import React from 'react';

const HeroSection: React.FC = () => {
  return (
    <section className="hero">
      <h2>Custom Software & Full Stack Solutions</h2>
      <p>Empowering small businesses with state-of-the-art digital solutions.</p>
    </section>
  );
}

export default HeroSection;
