import React from 'react';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <h1>SKYY STUDIO Inc.</h1>
      <div>
        <a href="#services">Services</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </div>
    </nav>
  );
}

export default Navbar;
